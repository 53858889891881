import * as Sentry from "@sentry/nextjs";
import ReactDOMServer from "react-dom/server";
import { SweetAlertOptions } from "sweetalert2";
import { theme } from "../../../stellmichein/src/styles/theme";

class AlertManager {
  static RegistrationSuccess(): SweetAlertOptions {
    return {
      title: "Account erstellt!",
      text: "Willkommen im Talentpool, du kannst dich jetzt mit einem neuen Account anmelden.",
      icon: "success",
      confirmButtonColor: theme.colors.buttonPrimary,
      confirmButtonText: "Alles klar",
    };
  }
  static ProfileImportedManually(first_name: string): SweetAlertOptions {
    return {
      title: "Hallo " + first_name + "!",
      text: "Wir haben dein Profil bereits anhand deiner früheren Angaben vorausgefüllt. Bitte überprüfe alle Felder noch einmal, vielleicht hat sich ja mittlerweile etwas bei dir verändert.",
      icon: "success",
      confirmButtonColor: theme.colors.primary,
      confirmButtonText: "Jetzt Profil editieren",
    };
  }
  static ProfileCreatedSuccess(): SweetAlertOptions {
    return {
      returnFocus: false,
      title: "Dein Profil wurde hinterlegt!",
      text: "" /*  Du bist jetzt im Talentpool sichtbar und Agenturen können sich bei dir telefonisch oder per Mail melden. */,
      icon: "success",
      confirmButtonColor: theme.colors.primary,
      confirmButtonText: "Alles klar",
    };
  }
  static ProfileCreatedError(
    err: any,
    email: string,
    talent?: Object
  ): SweetAlertOptions {
    let talentString = "";
    if (talent != null) {
      talentString = JSON.stringify(talent);
    }
    Sentry.captureException(
      new Error(
        `Failed to create Profile, talent is: ${talentString}, error is: ${JSON.stringify(
          err
        )}`
      )
    );
    return {
      returnFocus: false,
      title: "Hoppla! Hier hat etwas nicht funktioniert.",
      text: `Probiere es bitte später nochmal oder im Google Chrome Browser. Bei Fragen oder Problemen maile uns an ${email}. Danke!`,
      icon: "error",
      confirmButtonColor: theme.colors.error,
      confirmButtonText: "Alles klar",
    };
  }

  static ProfileUpdateDSGVO(preConfirm: any): SweetAlertOptions {
    return {
      customClass: {
        popup: "custom-swal-scrolling",
      },
      focusConfirm: false,
      allowEnterKey: false,
      title:
        "Einwilligung des Talents in die Datenerhebung/-verarbeitung/-nutzung ",
      html: ReactDOMServer.renderToStaticMarkup(
        <DSGVOPopupHTMLSME />
      ).toString(),
      icon: "question",
      confirmButtonColor: theme.colors.primary,
      confirmButtonText: "Ich stimme zu",
      showLoaderOnConfirm: true,
      preConfirm: preConfirm,
    };
  }
  static ProfileUpdateDSGVOEB(preConfirm: any): SweetAlertOptions {
    return {
      customClass: {
        popup: "custom-swal-scrolling",
      },
      focusConfirm: false,
      allowEnterKey: false,
      title:
        "Einwilligung des Talents in die Datenerhebung/-verarbeitung/-nutzung ",
      html: ReactDOMServer.renderToStaticMarkup(
        <DSGVOPopupHTMLEB />
      ).toString(),
      icon: "question",
      confirmButtonColor: theme.colors.primary,
      confirmButtonText: "Ich stimme zu",
      showLoaderOnConfirm: true,
      preConfirm: preConfirm,
    };
  }
  static AccountUpdateSuccess(): SweetAlertOptions {
    return {
      title: "Unternehmensprofil aktualisiert!",
      text: "Sie haben ihr Unternehmensprofil erfolgreich aktualisiert.",
      icon: "success",
      confirmButtonColor: theme.colors.buttonPrimary,
      confirmButtonText: "Alles klar",
    };
  }

  static AdminAccountCreateSuccess(): SweetAlertOptions {
    return {
      title: "Unternehmensprofil erstellt!",
      text: "Sie haben das Unternehmensprofil erfolgreich angelegt.",
      icon: "success",
      confirmButtonColor: theme.colors.buttonPrimary,
      confirmButtonText: "Alles klar",
    };
  }

  static AdminAccountCreateError(err: any): SweetAlertOptions {
    Sentry.captureException(
      new Error(
        `Failed to create account as admin, error is: ${JSON.stringify(err)}`
      )
    );
    return {
      title: "Unternehmensprofil konnte nicht erstellt werden.",
      text: "Kontakt: Christoph Meise, projects@explorechristoph.com",
      icon: "error",
      confirmButtonColor: theme.colors.buttonPrimary,
      confirmButtonText: "Alles klar",
    };
  }

  static AccountUpdateError(err: any): SweetAlertOptions {
    Sentry.captureException(
      new Error(`Failed to update Account, error is: ${JSON.stringify(err)}`)
    );
    return {
      title: "Unternehmensprofil konnte nicht aktualisiert werden.",
      text: "Bitte probiere es zu einem späteren Zeitpunkt nochmal. Sollte das Problem weiterhin bestehen, kontaktiere uns gerne.",
      icon: "error",
      confirmButtonColor: theme.colors.buttonPrimary,
      confirmButtonText: "Alles klar",
    };
  }

  static BillingErrorSendEmail(): SweetAlertOptions {
    return {
      title:
        "Es ist ein Problem aufgetreten, der Job konnte nicht angelegt werden.",
      text: "Bitte probiere es zu einem späteren Zeitpunkt nochmal. Sollte das Problem weiterhin bestehen, kontaktiere uns gerne.",
      icon: "error",
      confirmButtonColor: theme.colors.buttonPrimary,
      confirmButtonText: "Alles klar",
    };
  }

  static EmailVerifyError(err: any): SweetAlertOptions {
    Sentry.captureException(
      new Error(`Failed to verify Email, error is: ${JSON.stringify(err)}`)
    );
    return {
      returnFocus: false,
      title: "Ups, da hat leider etwas nicht funktioniert.",
      text: "Bitte überprüfe, ob der Code aus der Email exakt eingegeben wurde. Sollte das Problem weiterhin bestehen, kontaktiere uns gerne.",
      icon: "error",
      confirmButtonColor: theme.colors.error,
      confirmButtonText: "Alles klar",
    };
  }

  static EmailResendCodeSuccess(): SweetAlertOptions {
    return {
      returnFocus: false,
      title: "Wir haben dir eine Email mit einem neuen Code geschickt.",
      text: "Bitte überprüfe deine Inbox, es sollte jeden Moment eine Mail von uns ankommen.",
      icon: "success",
      confirmButtonColor: theme.colors.buttonPrimary,
      confirmButtonText: "Alles klar",
    };
  }

  static EmailResendCodeError(err: any): SweetAlertOptions {
    Sentry.captureException(
      new Error(`Failed to resend email code, error is: ${JSON.stringify(err)}`)
    );

    return {
      returnFocus: false,
      title: "Ups, da hat leider etwas nicht funktioniert.",
      text: "Bitte probiere es zu einem späteren Zeitpunkt nochmal. Sollte das Problem weiterhin bestehen, kontaktiere uns gerne.",
      icon: "error",
      confirmButtonColor: theme.colors.error,
      confirmButtonText: "Alles klar",
    };
  }

  static EmailResendCodeErrorNoMail(): SweetAlertOptions {
    return {
      returnFocus: false,
      title: "Ups, da hat leider etwas nicht funktioniert.",
      text: "Dein Link scheint nicht korrekt zu sein, in der URL fehlt deine Email bei email=deine.email@abc.de",
      icon: "error",
      confirmButtonColor: theme.colors.error,
      confirmButtonText: "Alles klar",
    };
  }

  static ForgotPasswordResetSuccess(): SweetAlertOptions {
    return {
      title: "Neues Passwort erfolgreich erstellt!",
      text: "Du kannst dich jetzt mit deinem neuen Passwort anmelden.",
      icon: "success",
      confirmButtonColor: theme.colors.buttonPrimary,
      confirmButtonText: "Alles klar",
    };
  }

  static ActiveCampaignMessageSuccess(): SweetAlertOptions {
    return {
      title: "Wir haben deine Anfrage erhalten.",
      text: "Vielen Dank!",
      icon: "success",
      confirmButtonColor: theme.colors.buttonPrimary,
      confirmButtonText: "Bestätigen",
    };
  }

  static ActiveCampaignMessageSuccessNewsletter(): SweetAlertOptions {
    return {
      title: "Wir haben deine Newsletter-Anmeldung erhalten.",
      text: "Vielen Dank!",
      icon: "success",
      confirmButtonColor: theme.colors.buttonPrimary,
      confirmButtonText: "Bestätigen",
    };
  }

  static ActiveCampaignMessageError(
    err: any,
    formData: FormData
  ): SweetAlertOptions {
    let formDataString = "";
    if (formData != null) {
      for (var pair of formData.entries()) {
        formDataString += pair[0] + ", " + pair[1];
      }
    }

    Sentry.captureException(
      new Error(
        `Failed to send ActiveCampaign Form, formData: ${formDataString}, error is: ${JSON.stringify(
          err
        )}`
      )
    );
    return {
      title: "Leider ist ein Problem aufgetreten!",
      text: "Bitte versuche es zu einem späteren Zeitpunkt noch einmal, oder kontaktiere uns per E-Mail.",
      icon: "error",
      confirmButtonColor: theme.colors.error,
      confirmButtonText: "Bestätigen",
    };
  }

  static ConfirmDeleteJob(name: string): SweetAlertOptions {
    return {
      title: `Möchten Sie den Job ${name} wirklich dauerhaft löschen?`,
      text: "Der Job wird offline genommen und unwiderruflich vom Account entfernt.",
      icon: "warning",
      confirmButtonColor: theme.colors.error,
      confirmButtonText: "Job endgültig löschen",
    };
  }
  static ConfirmBookJob(): SweetAlertOptions {
    return {
      title: "Jobanzeige gebucht!",
      text: "Wir haben deine Jobanzeige veröffentlicht.",
      icon: "success",
      confirmButtonColor: theme.colors.buttonPrimary,
      confirmButtonText: "Alles klar",
    };
  }
  static ConfirmUpdateJob(): SweetAlertOptions {
    return {
      title: "Jobanzeige aktualisiert!",
      text: "Wir haben deine Jobanzeige aktualisiert und die Änderungen werden in Kürze veröffentlicht.",
      icon: "success",
      confirmButtonColor: theme.colors.buttonPrimary,
      confirmButtonText: "Alles klar",
    };
  }

  static DeleteJobSuccess(): SweetAlertOptions {
    return {
      title: "Der Job wurde erfolgreich gelöscht",
      text: "Der Job wurde offline genommen und unwiderruflich vom Account entfernt.",
      icon: "success",
      confirmButtonColor: theme.colors.buttonPrimary,
      confirmButtonText: "Alles klar",
    };
  }
  static DeleteJobError(err: any): SweetAlertOptions {
    Sentry.captureException(
      new Error(`Failed to delete Job, error is: ${JSON.stringify(err)}`)
    );
    return {
      title: "Leider konnte der Job nicht gelöscht werden",
      text: "Bitte versuche es zu einem späteren Zeitpunkt noch einmal, oder kontaktiere uns per E-Mail.",
      icon: "error",
      confirmButtonColor: theme.colors.buttonPrimary,
      confirmButtonText: "Alles klar",
    };
  }

  static JobExpired(): SweetAlertOptions {
    return {
      title: "Dieser Job ist bereits abgelaufen.",
      text: "Schau dir gerne unseren aktuellen Jobangebote an",
      icon: "info",
      confirmButtonColor: theme.colors.buttonPrimary,
      confirmButtonText: "Zu den Jobs",
    };
  }
}
const DSGVOPopupHTMLEB = () => {
  return (
    <div>
      <p
        style={{
          color: "red",
          fontWeight: "bold",
          fontSize: "18px",
        }}
      >
        Hinweis: Am Tablet bitte mit zwei Fingern scrollen, lesen und dann ganz
        unten bestätigen.
      </p>
      <strong>Information des Talents über die Datenverarbeitung</strong>
      <p>
        Für ihre Vermittlung über unser Portal „Talentpool“ ist es unumgänglich,
        dass Ihre personenbezogenen Daten von uns gespeichert und verarbeitet
        werden. Hiermit möchten wir Sie über den Zweck und die Art der
        Verarbeitung informieren. Mit diesem Informationsschreiben erfüllen wir
        unter anderem die uns durch das Datenschutzrecht auferlegten
        Aufklärungspflichten, insbesondere im Sinne des Art. 13 DS-GVO. 
      </p>
      <strong>
        § 1 Verantwortlicher (Art. 13 Abs. 1 lit. a) DS-GVO) und
        Datenschutzbeauftragter des Verantwortlichen (Art. 13 Abs. 1 lit. b)
        DS-GVO)
      </strong>
      <p>
        Verantwortlicher im Sinne der DS-GVO ist die easyboarding UG
        (haftungsbeschränkt), Langbehnstraße 10, 22761 Hamburg, vertreten durch
        den Geschäftsführer Steven Hille.
      </p>
      <strong>
        § 2 Zweck der Datenspeicherung und -verarbeitung (Art. 13 Abs. 1 lit. c)
        DS-GVO){" "}
      </strong>
      <p>
        Ihre personenbezogenen Daten werden ausschließlich zur Ermöglichung des
        Recruiting- und Vermittlungsprozesses auf dem Portal „Talentpool“ und
        für unsere Speed-Recruting-Events verwendet. Dies umfasst die Erfassung
        und weitere Bearbeitung sowie die notwendige Bearbeitung im Rahmen eines
        vorvertraglichen Rechtsverhältnisses.{" "}
      </p>
      <strong>
        § 3 Notwendigkeit der Bereitstellung der Daten (Art. 13 Abs. 2 lit. e)
        DS-GVO){" "}
      </strong>
      <p>
        Ohne die Bereitstellung Ihrer personenbezogenen Daten können wir Ihre
        Bewerbung leider nicht bearbeiten.{" "}
      </p>
      <strong>§ 4 Umfang der personenbezogenen Daten </strong>
      <p>
        (1) Die von uns zum Zwecke des Talentpools verarbeitenden Daten umfassen
        Ihre Stamm- und Kontaktdaten, Ihre Daten über Ihre beruflichen
        Erfahrungen, Kenntnisse und Qualifikationen und das von ihnen zur
        Verfügung gestellt Foto.{" "}
      </p>
      <p>
        (2) Im Falle einer Online-Bewerbung kommen zusätzlich Ihre
        Online-Nutzungsdaten hinzu. {" "}
      </p>
      <p>
        (3) Wir haften nicht für die unbefugte und zweckwidrige Nutzungen ihrer
        Daten durch Dritte. Das gilt insbesondere für das unbefugte
        Herunterladen oder Kopieren des veröffentlichten Fotos.
      </p>
      <p>
        (4) Eine über die Zwecke des Talentpools – Recruiting/Vermittlung –
        hinausgehende Verwertung (Verkauf, Abtretung) ihrer veröffentlichten
        Bilder erfolgt nicht.
      </p>
      <strong>
        § 5 Rechtsgrundlage für die Datenspeicherung und -verarbeitung
      </strong>
      <p>
        Die Rechtsgrundlage dieser Datenspeicherung und -verarbeitung bildet
        Art. 6 Abs. 1 S. 1b DS-GVO.
      </p>
      <strong>§ 6 Empfänger der Daten (Art. 13 Abs. 1 lit. e) DS-GVO)</strong>
      <p>
        Wir stellen ihre Daten auf unserem Portal „Talentpool“ sowie den
        Speed-Recruiting-Events ausschließlich registrierten Unternehmen und
        Auftraggebern zur Verfügung, die auf der Suche nach geeigneten Talenten
        sind. Darüber hinaus stellen wir die Daten keinen Dritten zur Verfügung,
        es sei denn, eine darüber hinaus gehende Weitergabe ist zur Erfüllung
        gesetzlicher Pflichten notwendig.
      </p>
      <strong>§ 7 Dauer der Speicherung (Art. 13 Abs. 2 lit. a) DS-GVO)</strong>
      <p>
        Die Speicherung Ihrer personenbezogenen Daten dauert so lange an, wie
        ihr Profil aktiv ist und bis sämtliche mögliche Rechtsfolgen nicht
        länger entstehen können, die einen Nachweis erforderlich machen. Dies
        gilt insbesondere auch bis zum Ablauf von Verjährungsfristen.
      </p>
      <strong>
        § 8 Rechte der Betroffenen (Art. 13 Abs. 2 lit. b) ff. DS-GVO)
      </strong>
      <p>Ihnen steht jederzeit das Recht zu,</p>
      <p>
        (1) gem. Art. 15 DS-GVO Auskunft vom Verantwortlichen (§ 1) über die Sie
        betreffenden verarbeiteten personenbezogenen Daten zu verlangen.
        Insbesondere steht Ihnen eine Auskunft über die Verarbeitungszwecke
        (Art. 15 Abs. 1 lit. a) DS-GVO), die Kategorien der verarbeiteten
        personenbezogenen Daten (Art. 15 Abs. 1 lit. b) DS-GVO), die Kategorien
        von Empfängern, gegenüber denen Daten offengelegt wurden oder werden
        (Art. 15 Abs. 1 lit. c) DS-GVO), die geplante Speicherdauer (Art. 15
        Abs. 1 lit. d) DS-GVO), das Bestehen eines Rechts auf Berichtigung,
        Löschung, Einschränkung der Verarbeitung oder Widerspruchs (Art. 15
        Abs. 1 lit. e) DS-GVO), das Bestehen eines Beschwerderechts (Art. 15
        Abs. 1 lit. f) DS-GVO), die Herkunft der Daten, sofern diese nicht beim
        Verantwortlichen erhoben wurden (Art. 15 Abs. 1 lit. g) DS-GVO) sowie
        über das Bestehen einer automatisierten Entscheidungsfindung
        einschließlich Profiling und ggfs. aussagekräftigen Informationen zu
        deren Einzelheiten (Art. 15 Abs. 1 lit. h) DS-GVO) zu verlangen
      </p>
      <p>
        (2) gem. Art. 16 DS-GVO vom Verantwortlichen (§ 1) unverzüglich die
        Berichtigung Sie betreffender unrichtiger Daten oder die
        Vervollständigung der beim Verantwortlichen (§ 1) gespeicherten
        personenbezogenen Daten zu verlangen
      </p>
      <p>
        (3) gem. Art. 7 Abs. 3 DS-GVO eine ggfs. erteilte Einwilligung jederzeit
        zu widerrufen - dies berührt nicht die Rechtmäßigkeit der bis zu Ihrem
        Widerruf erfolgten Datenverarbeitung. Ihren Widerruf richten sie an
        hi@easyboarding.de.
      </p>
      <p>
        (4) gem. Art. 17 DS-GVO vom Verantwortlichen (§ 1) die Löschung der Sie
        betreffenden gespeicherten personenbezogenen Daten zu verlangen,
        insbesondere sofern diese Daten für die Zwecke, für die sie erhoben oder
        auf sonstige Weise verarbeitet wurden, nicht mehr notwendig sind oder
        die Daten unrechtmäßig verarbeitet wurden. Etwas anderes gilt, sofern
        die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und
        Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen
        des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder
        Verteidigung von Rechtsansprüchen erforderlich ist (Art. 17 Abs. 3
        DS-GVO){" "}
      </p>
      <p>
        (5) gem. Art. 18 DS-GVO vom Verantwortlichen (§ 1) die Einschränkung der
        Verarbeitung der personenbezogenen Daten zu verlangen, sofern die
        Richtigkeit der Daten bestritten wird (Art. 18 Abs. 1a) Ds-GVO), die
        Verarbeitung unrechtmäßig ist und Sie deren Löschung ablehnen (Art. 18
        Abs. 1b) DS-GVO) oder der Verantwortliche die Daten nicht mehr benötigt,
        Sie jedoch diese zur Geltendmachung, Ausübung oder Verteidigung von
        Rechtsansprüchen benötigen (Art. 18 Abs. 1c) DS-GVO) oder Sie gem.
        Art. 21 DS-GVO Widerspruch gegen die Verarbeitung eingelegt haben{" "}
      </p>
      <p>
        (6) gem. Art. 20 Abs. 1 DS-GVO die personenbezogenen Daten, die Sie
        bereitgestellt haben, in einem strukturierten, gängigen und
        maschinenlesbaren Format zu erhalten oder die Übermittlung an einen
        anderen Verantwortlichen zu verlangen{" "}
      </p>
      <p>
        (7) gem. Art. 77 DS-GVO sich bei einer Aufsichtsbehörde zu beschweren,
        sollten Sie der Ansicht sein, dass gegen Ihre Rechte verstoßen wurde.{" "}
      </p>
    </div>
  );
};
const DSGVOPopupHTMLSME = () => {
  return (
    <div>
      <p
        style={{
          color: "red",
          fontWeight: "bold",
          fontSize: "18px",
        }}
      >
        Hinweis: Am Tablet bitte mit zwei Fingern scrollen, lesen und dann ganz
        unten bestätigen.
      </p>
      <strong>Information des Talents über die Datenverarbeitung</strong>
      <p>
        Für ihre Vermittlung über unser Portal „Talentpool“ ist es unumgänglich,
        dass Ihre personenbezogenen Daten von uns gespeichert und verarbeitet
        werden. Hiermit möchten wir Sie über den Zweck und die Art der
        Verarbeitung informieren. Mit diesem Informationsschreiben erfüllen wir
        unter anderem die uns durch das Datenschutzrecht auferlegten
        Aufklärungspflichten, insbesondere im Sinne des Art. 13 DS-GVO. {" "}
      </p>
      <strong>
        § 1 Verantwortlicher (Art. 13 Abs. 1 lit. a) DS-GVO) und
        Datenschutzbeauftragter des Verantwortlichen (Art. 13 Abs. 1 lit. b)
        DS-GVO){" "}
      </strong>
      <p>
        Verantwortlicher im Sinne der DS-GVO ist die prismus communications
        GmbH, Lehmbruckstraße 24, 10245 Berlin, vertreten durch den
        Geschäftsführer Lars Dörfel.
      </p>
      <strong>
        § 2 Zweck der Datenspeicherung und -verarbeitung (Art. 13 Abs. 1 lit. c)
        DS-GVO){" "}
      </strong>
      <p>
        Ihre personenbezogenen Daten werden ausschließlich zur Ermöglichung des
        Recruiting- und Vermittlungsprozesses auf dem Portal „Talentpool“ und
        für unsere Speed-Recruting-Events verwendet. Dies umfasst die Erfassung
        und weitere Bearbeitung sowie die notwendige Bearbeitung im Rahmen eines
        vorvertraglichen Rechtsverhältnisses.{" "}
      </p>
      <strong>
        § 3 Notwendigkeit der Bereitstellung der Daten (Art. 13 Abs. 2 lit. e)
        DS-GVO){" "}
      </strong>
      <p>
        Ohne die Bereitstellung Ihrer personenbezogenen Daten können wir Ihre
        Bewerbung leider nicht bearbeiten.{" "}
      </p>
      <strong>§ 4 Umfang der personenbezogenen Daten </strong>
      <p>
        (1) Die von uns zum Zwecke des Talentpools verarbeitenden Daten umfassen
        Ihre Stamm- und Kontaktdaten, Ihre Daten über Ihre beruflichen
        Erfahrungen, Kenntnisse und Qualifikationen und das von ihnen zur
        Verfügung gestellt Foto.{" "}
      </p>
      <p>
        (2) Im Falle einer Online-Bewerbung kommen zusätzlich Ihre
        Online-Nutzungsdaten hinzu. {" "}
      </p>
      <p>
        (3) Wir haften nicht für die unbefugte und zweckwidrige Nutzungen ihrer
        Daten durch Dritte. Das gilt insbesondere für das unbefugte
        Herunterladen oder Kopieren des veröffentlichten Fotos.
      </p>
      <p>
        (4) Eine über die Zwecke des Talentpools – Recruiting/Vermittlung –
        hinausgehende Verwertung (Verkauf, Abtretung) ihrer veröffentlichten
        Bilder erfolgt nicht.
      </p>
      <strong>
        § 5 Rechtsgrundlage für die Datenspeicherung und -verarbeitung
      </strong>
      <p>
        Die Rechtsgrundlage dieser Datenspeicherung und -verarbeitung bildet
        Art. 6 Abs. 1 S. 1b DS-GVO.
      </p>
      <strong>§ 6 Empfänger der Daten (Art. 13 Abs. 1 lit. e) DS-GVO)</strong>
      <p>
        Wir stellen ihre Daten auf unserem Portal „Talentpool“ sowie den
        Speed-Recruiting-Events ausschließlich registrierten Unternehmen und
        Auftraggebern zur Verfügung, die auf der Suche nach geeigneten Talenten
        sind. Darüber hinaus stellen wir die Daten keinen Dritten zur Verfügung,
        es sei denn, eine darüber hinaus gehende Weitergabe ist zur Erfüllung
        gesetzlicher Pflichten notwendig.
      </p>
      <strong>§ 7 Dauer der Speicherung (Art. 13 Abs. 2 lit. a) DS-GVO)</strong>
      <p>
        Die Speicherung Ihrer personenbezogenen Daten dauert so lange an, wie
        ihr Profil aktiv ist und bis sämtliche mögliche Rechtsfolgen nicht
        länger entstehen können, die einen Nachweis erforderlich machen. Dies
        gilt insbesondere auch bis zum Ablauf von Verjährungsfristen.
      </p>
      <strong>
        § 8 Rechte der Betroffenen (Art. 13 Abs. 2 lit. b) ff. DS-GVO)
      </strong>
      <p>Ihnen steht jederzeit das Recht zu,</p>
      <p>
        (1) gem. Art. 15 DS-GVO Auskunft vom Verantwortlichen (§ 1) über die Sie
        betreffenden verarbeiteten personenbezogenen Daten zu verlangen.
        Insbesondere steht Ihnen eine Auskunft über die Verarbeitungszwecke
        (Art. 15 Abs. 1 lit. a) DS-GVO), die Kategorien der verarbeiteten
        personenbezogenen Daten (Art. 15 Abs. 1 lit. b) DS-GVO), die Kategorien
        von Empfängern, gegenüber denen Daten offengelegt wurden oder werden
        (Art. 15 Abs. 1 lit. c) DS-GVO), die geplante Speicherdauer (Art. 15
        Abs. 1 lit. d) DS-GVO), das Bestehen eines Rechts auf Berichtigung,
        Löschung, Einschränkung der Verarbeitung oder Widerspruchs (Art. 15
        Abs. 1 lit. e) DS-GVO), das Bestehen eines Beschwerderechts (Art. 15
        Abs. 1 lit. f) DS-GVO), die Herkunft der Daten, sofern diese nicht beim
        Verantwortlichen erhoben wurden (Art. 15 Abs. 1 lit. g) DS-GVO) sowie
        über das Bestehen einer automatisierten Entscheidungsfindung
        einschließlich Profiling und ggfs. aussagekräftigen Informationen zu
        deren Einzelheiten (Art. 15 Abs. 1 lit. h) DS-GVO) zu verlangen
      </p>
      <p>
        (2) gem. Art. 16 DS-GVO vom Verantwortlichen (§ 1) unverzüglich die
        Berichtigung Sie betreffender unrichtiger Daten oder die
        Vervollständigung der beim Verantwortlichen (§ 1) gespeicherten
        personenbezogenen Daten zu verlangen
      </p>
      <p>
        (3) gem. Art. 7 Abs. 3 DS-GVO eine ggfs. erteilte Einwilligung jederzeit
        zu widerrufen - dies berührt nicht die Rechtmäßigkeit der bis zu Ihrem
        Widerruf erfolgten Datenverarbeitung. Ihren Widerruf richten sie an
        loeschen@stellmichein.de.
      </p>
      <p>
        (4) gem. Art. 17 DS-GVO vom Verantwortlichen (§ 1) die Löschung der Sie
        betreffenden gespeicherten personenbezogenen Daten zu verlangen,
        insbesondere sofern diese Daten für die Zwecke, für die sie erhoben oder
        auf sonstige Weise verarbeitet wurden, nicht mehr notwendig sind oder
        die Daten unrechtmäßig verarbeitet wurden. Etwas anderes gilt, sofern
        die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und
        Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen
        des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder
        Verteidigung von Rechtsansprüchen erforderlich ist (Art. 17 Abs. 3
        DS-GVO){" "}
      </p>
      <p>
        (5) gem. Art. 18 DS-GVO vom Verantwortlichen (§ 1) die Einschränkung der
        Verarbeitung der personenbezogenen Daten zu verlangen, sofern die
        Richtigkeit der Daten bestritten wird (Art. 18 Abs. 1a) Ds-GVO), die
        Verarbeitung unrechtmäßig ist und Sie deren Löschung ablehnen (Art. 18
        Abs. 1b) DS-GVO) oder der Verantwortliche die Daten nicht mehr benötigt,
        Sie jedoch diese zur Geltendmachung, Ausübung oder Verteidigung von
        Rechtsansprüchen benötigen (Art. 18 Abs. 1c) DS-GVO) oder Sie gem.
        Art. 21 DS-GVO Widerspruch gegen die Verarbeitung eingelegt haben{" "}
      </p>
      <p>
        (6) gem. Art. 20 Abs. 1 DS-GVO die personenbezogenen Daten, die Sie
        bereitgestellt haben, in einem strukturierten, gängigen und
        maschinenlesbaren Format zu erhalten oder die Übermittlung an einen
        anderen Verantwortlichen zu verlangen{" "}
      </p>
      <p>
        (7) gem. Art. 77 DS-GVO sich bei einer Aufsichtsbehörde zu beschweren,
        sollten Sie der Ansicht sein, dass gegen Ihre Rechte verstoßen wurde.{" "}
      </p>
    </div>
  );
};

export default AlertManager;
